import { css } from 'styled-components';

const variables = css`
  :root {
    --math-dark-navy: #314450;
    --math-navy: #466273;
    --math-light-navy: #90a0ab;
    --math-dark-navy-shadow: rgb(37, 55, 89, 0.1);

    --math-dark-slate: #4c5656;
    --math-slate: #99adad;
    --math-light-slate: #b7c5c5;
    --math-lightest-slate: #d6dede;

    --math-royal-yellow: #ccae73;
    --math-royal-yellow-tint: rgba(204, 174, 115, 0.1);

    --dark-navy: #1a1a40;
    --navy: #270082;
    --light-navy: #112240;
    --lightest-navy: #233554;
    --navy-shadow: rgba(2, 12, 27, 0.7);

    --dark-slate: #495670;
    --slate: #8892b0;
    --light-slate: #a8b2d1;
    --lightest-slate: #ccd6f6;

    --white: #e6f1ff;

    --green: #64ffda;
    --green-tint: rgba(100, 255, 218, 0.1);

    --pink: #fa58b6;
    --blue: #57cbff;

    --font-sans: 'Calibre', 'Inter', 'San Francisco', 'SF Pro Text', -apple-system, system-ui,
      sans-serif;
    --font-mono: 'SF Mono', 'Fira Code', 'Fira Mono', 'Roboto Mono', monospace;

    --fz-xxs: 12px;
    --fz-xs: 13px;
    --fz-sm: 14px;
    --fz-md: 16px;
    --fz-lg: 18px;
    --fz-xl: 20px;
    --fz-xxl: 22px;
    --fz-heading: 32px;

    --border-radius: 4px;
    --nav-height: 100px;
    --nav-scroll-height: 100px;

    --tab-height: 42px;
    --tab-width: 120px;

    --easing: cubic-bezier(0.645, 0.045, 0.355, 1);
    --transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);

    --hamburger-width: 30px;

    --ham-before: top 0.1s ease-in 0.25s, opacity 0.1s ease-in;
    --ham-before-active: top 0.1s ease-out, opacity 0.1s ease-out 0.12s;
    --ham-after: bottom 0.1s ease-in 0.25s, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    --ham-after-active: bottom 0.1s ease-out,
      transform 0.22s cubic-bezier(0.215, 0.61, 0.355, 1) 0.12s;
  }
`;

export default variables;
